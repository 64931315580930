<template>
  <Portlet
    :title="'Photopoint'"
    icon="camera"
    class="photopointMediaReview"
  >
    <template slot="buttons">
      <select
        v-model="day"
        class="mr-3"
      >
        <option
          :value="1"
          selected
        >
          Yesterday
        </option>
        <option
          :value="2"
        >
          Two days ago
        </option>
        <option
          :value="3"
        >
          Three days ago
        </option>
        <option
          :value="4"
        >
          Four days ago
        </option>
        <option
          :value="5"
        >
          Five days ago
        </option>
        <option
          :value="6"
        >
          Six days ago
        </option>
        <option
          :value="7"
        >
          Seven days ago
        </option>
      </select>
    </template>
    <LoadingPlaceholder v-if="loading" />
    <template v-else-if="photopointRuns.length > 0">
      <div
        v-for="(item, index) in photopointRuns"
        :key="`photoRun-${ index }`"
      >
        <router-link
          :to="`/centraldevicemanagement/searchdevices/${ item.cameraName }`"
          class="alt-primary-color"
        >
          <h4 :class="index != 0 ? 'mt-3' : ''">
            {{ item.cameraName }}
          </h4>
        </router-link>
        <div class="row">
          <div>
            <p style="margin-bottom:0px; text-align:center;">
              10:00 - 11:00
            </p>
            <MediaReviewPhotopointSessionOverview
              :photo-point="item.firstItem"
            />
          </div>
          <div>
            <p style="margin-bottom:0px; text-align:center;">
              13:00 - 14:00
            </p>
            <MediaReviewPhotopointSessionOverview
              :photo-point="item.secondItem"
            />
          </div>
          <div>
            <p style="margin-bottom:0px; text-align:center;">
              15:00 - 16:00
            </p>
            <MediaReviewPhotopointSessionOverview
              :photo-point="item.thirdItem"
            />
          </div>
        </div>
      </div>
    </template>
    <p v-else>
      {{ $t('noDataAvailable') }}
    </p>    
  </Portlet>
</template>

<script>
export default {
  name: "PhotopointMediaReview",
  components: {
    MediaReviewPhotopointSessionOverview: () => import("@/components/MediaReview/MediaReviewPhotopointSessionOverview.vue"),
  },
  metaInfo () {
    return {
      title: "Photopoint"
    };
  },
  data () {
    return {
      day: 1,
      loading: false,
      photopointRuns: null
    };
  },
  watch: {
    day () {
      this.getPhotopointRuns();
    }
  },
  created () {
    this.getPhotopointRuns();
  },
  methods: {
    //#region API-calls
    getPhotopointRuns () {
      this.loading = true;
      this.axios
        .get(`/Media/GetPhotopointMediaReview?day=${this.day}`)
        .then(response => {
          this.photopointRuns = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script> 